import { Link as RRLink } from 'react-router-dom'
import { Link } from '../../02-components'
import { ProductTable } from '../../03-ui'
import { ACCOUNT } from '../../07-data/paths'
import {
    BUSINESS_CHECKING_ACCOUNT,
    BUSINESS_LINK_ACCOUNT,
    BUSINESS_SAVINGS_ACCOUNT,
} from '../../08-util/checkTypeOfAccount'

function Home() {
    return (
        <div className="home">
            <ProductTable
                actionText="Apply Now"
                renderBusinessSavingsAction={(props) => (
                    <RRLink
                        {...props}
                        to={`${ACCOUNT}/${BUSINESS_SAVINGS_ACCOUNT}`}
                    />
                )}
                renderBusinessCheckingAction={(props) => (
                    <RRLink
                        {...props}
                        to={`${ACCOUNT}/${BUSINESS_CHECKING_ACCOUNT}`}
                    />
                )}
                renderBusinessLinkProceedAction={(props) => (
                    <Link
                        {...props}
                        to={`${ACCOUNT}/${BUSINESS_LINK_ACCOUNT}`}
                    />
                )}
            />
        </div>
    )
}

export default Home
